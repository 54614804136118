import React, { useMemo } from 'react';
import styles from './areas-giving.module.scss';
import Img from 'gatsby-image';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';

const AreasGiving = ({ data }) => {
  const staticImage = useImageStaticQuery(data.image, data.cmsImage);
  const image = get(staticImage, 'childImageSharp.fluid', get(staticImage, 'fluid', null));

  return useMemo(
    () => (
      <div className={styles.areasGiving}>
        <div className={styles.areasGivingContainer}>
          <div className={styles.areasGivingWrapper}>
            <div className={styles.areasGivingImageWrapper}>
              {image !== null && <Img
                fluid={image}
                className={styles.areasGivingImage}
                alt="Areas of giving feature"
              />}
            </div>
            <div className={styles.areasGivingContent}>
              <h3 className={styles.areasGivingHeading}>{data.title}</h3>
              <div dangerouslySetInnerHTML={{__html:data.body}}/>
            </div>
          </div>
        </div>
      </div>
    ),
    [data],
  );
};

AreasGiving.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};

export default AreasGiving;
