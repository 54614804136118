import React from 'react';
import { get } from 'lodash';
import styles from './request-support.module.scss';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import * as PropTypes from 'prop-types';

const RequestSupport = ({ data }) => {
  return (
    <div className={styles.requestSupport}>
      <div className={styles.requestSupportContainer}>
        {data.title && <h3 className={styles.requestSupportTitle}>{data.title}</h3>}
        <div className={styles.requestSupportContent}>
          {data.content.map((item, itemid) => (
            <div key={itemid} className={styles.requestSupportSection}>
              <div className={styles.requestSupportImageWrapper}>
                {item.image !== null && <Img
                  className={styles.requestSupportImage}
                  fluid={item.image.fluid}
                  alt="Request Support"
                />}
              </div>
              <div className={styles.requestSupportText}>
                {item.heading && <h3 className={styles.requestSupportHeading}>{item.heading}</h3>}
                {item.summary &&
                <div className={styles.requestSupportSummary} dangerouslySetInnerHTML={{ __html: item.summary }}/>}
               {/*  {<Link to={item.link} className={styles.requestSupportLink}>
                  {item.linkTitle}
                </Link>} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

RequestSupport.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
        heading: PropTypes.string,
        summary: PropTypes.string,
        link: PropTypes.string,
        //linkTitle: PropTypes.string,
      }),
    ),
  }),
};

export default RequestSupport;
