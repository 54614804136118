// Libs.
import React from 'react';
// Assets.
import styles from './community-landing.module.scss';
import locale from '../../locale/locale';
// Libraries.
import { getContentSubItems } from '../../libs/static-content/static-content-utils';
// Components.
import CommunityHero from './community-hero';
import CharitableFoundation from './charitable-foundation';
import AreasGiving from './areas-giving';
import RequestSupport from './request-support';
import StaticContentList from '../common/static-content/static-content-list';
import { useInTheCommunityQuery } from '../../hooks/useInTheCommunityQuery';
import {useFAQQuery} from "../../hooks/useFAQPageQuery";

const CommunityLanding = () => {
  const data = useInTheCommunityQuery();
  const {configPagesFaq: {items: faqs} } = useFAQQuery();
  const requestSupport = data.requestSupport;
  requestSupport.content[0].link = '/corporate-giving-request';
  requestSupport.content[0].linkTitle = 'Submit Donation Request';
  requestSupport.content[1].link = '/store-giving-request';
  requestSupport.content[1].linkTitle = 'Submit Donation Request';

  // console.log('CommunityLanding', data, requestSupport);

  return (
    <div className={styles.communityLanding}>
      <CommunityHero data={data.hero}/>
      <CharitableFoundation data={data.featuredCta.content}/>
      <AreasGiving data={data.areasOfGiving}/>
      <RequestSupport data={requestSupport}/>
      <div className={styles.communityLandingWhiteWrapper}>
        <StaticContentList
          listingTitle={locale.faqs.title}
          items={getContentSubItems(faqs, 'Longo\'s Family Charitable Foundation', 3)}
          showViewAll={true}
          numItemsPerLine="3"
          viewAllLink="/faqs"
        />
      </div>
    </div>
  );
};

export default CommunityLanding;
