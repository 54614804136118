import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { get, has } from 'lodash';
// Components
import Img from 'gatsby-image';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import SetInnerHtml from '../common/set-inner-html/set-inner-html';
import SiteLink from '../common/site-link';
// Assets
import styles from './charitable-foundation.module.scss';

const CharitableFoundation = ({ data }) => {
  const staticImage = useImageStaticQuery(data.image, data.cmsImage);
  const image = get(staticImage, 'childImageSharp.fluid', get(staticImage, 'fluid', null));

  return useMemo(
    () => (
      <div className={styles.charitableFoundation}>
        <div className={styles.charitableFoundationContainer}>
          <div className={styles.charitableFoundationWrapper}>
            <div className={styles.charitableFoundationImageWrapper}>
              {image !== null && <Img
                fluid={image}
                className={styles.charitableFoundationImage}
                alt="Family Charitable Foundation"
              />}
            </div>
            <div className={styles.charitableFoundationContent}>
              <p className={styles.charitableFoundationTagName}>{data.tagName}</p>
              <h3 className={styles.charitableFoundationHeading}>{data.heading}</h3>
              <div className={styles.charitableFoundationBody}>
                <SetInnerHtml data={{ content: data.body }}/>
                {has(data.link, 'uri') && <SiteLink href={data.link.uri}>{data.link.title}</SiteLink>}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    [data],
  );
};

CharitableFoundation.propTypes = {
  data: PropTypes.shape({
    tagName: PropTypes.string,
    heading: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        subHeading: PropTypes.string,
        summary: PropTypes.string,
      }),
    ),
  }),
};

export default CharitableFoundation;
