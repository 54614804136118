// Libs
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
// Deps
import SiteLink from '../site-link';
import Img from 'gatsby-image';
// Assets
import styles from './heading-cta.module.scss';
import { get } from 'lodash';
import { useImageStaticQuery } from '../../../hooks/useImagesStaticQuery';

const HeadingCta = ({ data }) => {
  const image = get(data.image,'childImageSharp.fluid', get(data.image,'fluid', null));

  return useMemo(
    () => (
      <div className={styles.headingCta}>
        {image !== null && <Img className={styles.headingCtaImage} fluid={image} />}
        <div className={styles.headingCtaWrapper}>
          <div className={styles.headingCtaContent}>
            {data.title && <h1 className={styles.headingCtaTitle}>{data.title}</h1>}
            {data.subTitle && <h3 className={styles.headingCtaSubTitle} dangerouslySetInnerHTML={{__html: data.subTitle}}/>}
            {data.summary && <p className={styles.headingCtaSummary}>{data.summary}</p>}
            {data.link && <SiteLink href={data.link.uri}>{data.link.title}</SiteLink>}
          </div>
        </div>
      </div>
    ),
    [data]
  );
};

HeadingCta.defaultProps = {
  data: null,
};

HeadingCta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    summary: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
};

export default HeadingCta;
