import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import CommunityLanding from '../components/in-the-community/community-landing';

const InTheCommunity = () => (
  <Layout>
    <SEO title="In the community" keywords={[`gatsby`, `application`, `react`]} />
    <CommunityLanding />
  </Layout>
);

export default InTheCommunity;
