import React, { useMemo } from 'react';
import styles from './community-hero.module.scss';
import HeadingCta from '../common/heading-cta/heading-cta';
import { cloneDeep } from 'lodash';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';

const CommunityHero = ({ data }) => {
  const communityData = cloneDeep(data);
  communityData.image = useImageStaticQuery(data.image, data.cmsImage);

  return useMemo(
    () => (
      <div>
        <HeadingCta data={communityData} />
      </div>
    ),
    [data]
  );
};

export default CommunityHero;
